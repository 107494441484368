/* eslint-disable sort-keys */

import { AppEnv, Config as ConfigType, NodeEnv, TenantConfig, Timezone } from '~/core/declarations';

class Config implements ConfigType {
  ablyKey = process.env.ABLY_KEY || '';
  apiToken = process.env.API_TOKEN || '';
  apiUrl = process.env.API_URL || '';
  appEnv = (process.env.APP_ENV as AppEnv) || 'production';
  documentTitle = '';
  gtmId = process.env.GTM_ID;
  hotjarSiteId = process.env.HOTJAR_SITE_ID ? Number(process.env.HOTJAR_SITE_ID) : undefined; // Hotjars
  nodeEnv = (process.env.NODE_ENV as NodeEnv) || 'production';
  asyncExtends = async (config: ConfigType) => config;
  sentryDsn = process.env.SENTRY_DSN;
  styles!: ConfigType['styles'];
  timezone!: Timezone;
  tenant = process.env.TENANT || '';
  workflow!: ConfigType['workflow'];
  schemas!: ConfigType['schemas'];
  documents!: ConfigType['documents'];
  brokerPortalURL: ConfigType['brokerPortalURL'];
  dateFormat: ConfigType['dateFormat'];

  setTenantConfig(tenantConfig: TenantConfig): void {
    this.merge(tenantConfig);
    this.asyncExtends(tenantConfig as ConfigType).then((config) => this.merge(config));
  }

  private merge(tenantConfig: TenantConfig): void {
    Object.assign(this || {}, tenantConfig);
  }
}

const config = new Config();

export default config;
