import init from '~/init';
import { TenantModule } from '~/core/declarations';

async function main(): Promise<void> {
  // @IMPROVEMENT Can I pass `config.tenant` into the import statement?
  const tenantModule: TenantModule = await import(`./tenants/${process.env.TENANT}/module`).then((value) => value.default);

  const tenantConfig = await tenantModule.loadConfig();

  init(tenantConfig);

  const App = await tenantModule.loadApp();

  (await import('./render-app').then((value) => value.default))(App);

  if (tenantModule.loadSetup) {
    (await tenantModule.loadSetup())();
  }
}

main();
